import React from 'react';
import {
  Flex,
  HStack,
  Heading,
  Icon,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';

import { contHead, contacts, contBody } from './contactData';
import { themeHeader } from '../../themes/themes';

const themeGradient = {
  bgGradient: 'linear(to-l, #d19446 0%, #dcaf77 45%, #8c5e2a 100%)',
  bgClip: 'text',
  whiteSpace: 'nowrap',
};
export default function Contact() {
  return (
    <Flex id="contact-me-section" w="100%" justifyContent={'center'} pb={20}>
      <VStack maxW={1000}>
        <Heading {...themeHeader}>{contHead}</Heading>
        <Text
          p={4}
          fontSize={{ base: '15px', md: '25px', lg: '36px' }}
          fontFamily={'Verdana (sans-serif)'}
          pb={10}
        >
          {contBody}
        </Text>
        <VStack align={'left'}>
          {contacts.map((contact, index) => (
            <VStack
              key={`contact${index}`}
              whiteSpace={'nowrap'}
              pb={10}
              // w={'100%'}
              // pl={['5px', '10%']}
              align={'left'}
            >
              <Text fontWeight={'bold'} fontSize={[14, 17, 25]}>
                {contact.header}
              </Text>
              <Link
                href={contact.url}
                _hover={{
                  transform: 'scale(1.02)',
                }}
              >
                <HStack>
                  <Icon as={contact.icon} className="icon" boxSize={4} />
                  <Text
                    minW={'180px'}
                    fontSize={[14, 15, 23]}
                    {...themeGradient}
                  >
                    {contact.name}
                  </Text>
                </HStack>
              </Link>
              {contact.mobile && (
                <Link
                  href={contact.mobile.url}
                  _hover={{
                    transform: 'scale(1.02)',
                  }}
                >
                  <HStack>
                    <Icon
                      as={contact.mobile.icon}
                      className="icon"
                      boxSize={4}
                    />
                    <Text
                      minW={'180px'}
                      fontSize={[14, 15, 23]}
                      {...themeGradient}
                    >
                      {contact.mobile.name}
                    </Text>
                  </HStack>
                </Link>
              )}
              {contact.loc && (
                <HStack>
                  <Icon as={contact.loc.icon} className="icon" boxSize={4} />
                  <Text
                    minW={'180px'}
                    fontSize={[14, 15, 23]}
                    {...themeGradient}
                  >
                    {contact.loc.name}
                  </Text>
                </HStack>
              )}
            </VStack>
          ))}
        </VStack>
      </VStack>
    </Flex>
  );
  // const [name, setName] = useState('');
  // const [business, setBusiness] = useState('');
  // const [email, setEmail] = useState('');
  // const [area, setArea] = useState('');
  // const [message, setMessage] = useState('');
  // const toast = useToast();
  // const handleSubmit = e => {
  //   e.preventDefault();
  //   if (message.length < 50) {
  //     toast({
  //       title: 'Message Too Short',
  //       description: 'Please enter minimun of 50 characters',
  //       status: 'error',
  //       duration: 3000,
  //       isClosable: true,
  //     });
  //     return;
  //   }
  //   // Example success toast
  //   toast({
  //     title: 'Form Submitted',
  //     description: 'Thank you for your message!',
  //     status: 'success',
  //     duration: 3000,
  //     isClosable: true,
  //   });
  //   // Reset form fields
  //   setName('');
  //   setEmail('');
  //   setArea('');
  //   setMessage('');
  // };
  // return (
  //   <Flex
  //     color="white"
  //     id="contact-me-section"
  //     justifyContent="center"
  //     bgImg={bgImage}
  //     bgPos="center"
  //     bgSize="cover"
  //     shadow="inset 0 0 0 2000px rgba(0,0,0,0.8)"
  //     pt={20}
  //     pb={40}
  //     px={5}
  //   >
  //     <Flex flexDir="column" maxW={500} w="100%">
  //       <form onSubmit={handleSubmit}>
  //         <VStack spacing={6} align="start">
  //           <Heading pb={8} fontSize={{ base: '40px', md: '50px' }}>
  //             Contact Form
  //           </Heading>
  //           <FormControl isRequired>
  //             <FormLabel>Name</FormLabel>
  //             <Input
  //               type="text"
  //               value={name}
  //               onChange={e => setName(e.target.value)}
  //               required
  //             />
  //           </FormControl>
  //           <FormControl>
  //             <FormLabel>Business Name</FormLabel>
  //             <Input
  //               type="text"
  //               value={business}
  //               onChange={e => setBusiness(e.target.value)}
  //               // required
  //             />
  //           </FormControl>
  //           <FormControl isRequired>
  //             <FormLabel>Email</FormLabel>
  //             <Input
  //               type="email"
  //               value={email}
  //               onChange={e => setEmail(e.target.value)}
  //               required
  //             />
  //           </FormControl>
  //           <FormControl>
  //             <FormLabel>Area</FormLabel>
  //             <Input
  //               type="text"
  //               value={area}
  //               onChange={e => setArea(e.target.value)}
  //               // required
  //             />
  //           </FormControl>
  //           <FormControl isRequired>
  //             <FormLabel>Message</FormLabel>
  //             <Textarea
  //               value={message}
  //               onChange={e => setMessage(e.target.value)}
  //               required
  //               minH={200}
  //             />
  //           </FormControl>
  //           <Button
  //             w={150}
  //             type="submit"
  //             color={'white'}
  //             bgGradient={'linear(to-l, #8c6804 0%, #dcaf77 45%, #8c5e2a 100%)'}
  //           >
  //             Submit
  //           </Button>
  //         </VStack>
  //       </form>
  //     </Flex>
  //   </Flex>
  // );
}
