import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Image,
  Text,
  SimpleGrid,
  useMediaQuery,
} from '@chakra-ui/react';
import moduleBg from '../../images/services/deck.jpg';

const ProjectModal = ({ isOpen, onClose, project }) => {
  const [wDevice] = useMediaQuery('(min-width: 500px)');
  return (
    <Modal isOpen={isOpen} onClose={onClose} size={['full', '6xl']}>
      <ModalOverlay />
      <ModalContent
        bgImage={moduleBg}
        borderRadius={20}
        shadow="inset 0 0 0 2000px rgba(0,0,0,0.9)"
      >
        <ModalHeader
          fontSize={[30, 40]}
          textShadow={'dark-lg'}
          color={'white'}
          fontWeight={'bold'}
        >
          {project.header}
        </ModalHeader>
        <ModalCloseButton color={'white'} />
        <ModalBody>
          <Text color={'white'} pb={5} fontSize={[20, 30]}>
            {project.modalContent}
          </Text>
          <SimpleGrid
            columns={2}
            spacing={10}
            minChildWidth={wDevice ? 600 : 280}
          >
            {project.images.map((image, index) => (
              <Image
                key={index}
                src={image}
                alt={`image${index}`}
                w="100%"
              ></Image>
            ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter>
          <Button
            color={'white'}
            bgGradient={'linear(to-l, #8c6804 0%, #dcaf77 45%, #8c5e2a 100%)'}
            mr={3}
            onClick={onClose}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProjectModal;
