import { FaMobile, FaEnvelope, FaMapLocationDot } from 'react-icons/fa6';

export const contHead = 'Contact Us';
export const contBody =
  "Hello! If you have a construction or building project in mind, we'd love to hear from you. Feel free to get in touch with us to discuss your project and learn how we can bring your ideas to life with our expertise and craftsmanship.";

export const contacts = [
  {
    header: 'Project in mind',
    icon: FaEnvelope,
    url: 'mailto: quote@solidbuildingaspect.co.uk',
    name: 'quote@solidbuildingaspect.co.uk',
  },
  {
    header: 'General enquires',
    icon: FaEnvelope,
    url: 'mailto: info@solidbuildingaspect.co.uk',
    name: 'info@solidbuildingaspect.co.uk',
  },
  {
    header: 'Contracting and Business Development',
    icon: FaEnvelope,
    url: 'mailto: sam@solidbuildingaspect.co.uk',
    name: 'sam@solidbuildingaspect.co.uk',
    mobile: {
      icon: FaMobile,
      name: '07737551182',
      url: 'tel: +447737551182',
    },
    loc: {
      icon: FaMapLocationDot,
      name: 'London',
    },
  },
];
