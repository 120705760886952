export const images = [];

function importAllImages(r, l) {
  r.keys().forEach(key => l.push(r(key)));
}

const imagesContext = require.context(
  '../../images/slider/',
  false,
  /\.(png|jpe?g|svg)$/
); // Adjust the file extensions as needed

importAllImages(imagesContext, images);
