import React, { useState } from 'react';
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Image,
  VStack,
  Heading,
  Text,
  SimpleGrid,
  useMediaQuery,
  useDisclosure,
  Button,
  Flex,
  Box,
} from '@chakra-ui/react';

import { projHead, projects } from './projectData.js';
import ProjectModal from './ProjectModal.js';
import { themeHeader, themeGradient } from '../../themes/themes.js';

export default function Projects() {
  const [wDevice] = useMediaQuery('(min-width: 400px)');
  const { isOpen, onOpen } = useDisclosure();

  const [projIndex, setProjIndex] = useState(null);
  const [subIndex, setSubIndex] = useState(null);

  const handleSeeMoreClick = (index, subI) => {
    setProjIndex(index);
    setSubIndex(subI);
    onOpen();
  };

  return (
    <Flex
      color={'black'}
      bgColor={'white'}
      bgSize={'100%'}
      id="projects-section"
      justifyContent={'center'}
      p={5}
    >
      <VStack>
        <Heading pb={8} {...themeHeader}>
          {projHead}
        </Heading>
        {projects.map((project, index) => (
          <VStack pt={5} key={`head${index}`}>
            <Box bgColor={'gray.700'} borderRadius={40} w={'100%'} p={2}>
              <Heading
                textAlign={'center'}
                fontSize={{ base: '100%', md: '35px' }}
                textShadow={'dark-lg'}
                {...themeGradient}
              >
                {project.header}
              </Heading>
            </Box>
            <SimpleGrid
              pt={5}
              spacing={8}
              w={'100%'}
              minChildWidth={wDevice ? 400 : 280}
            >
              {project.subProjects.map((subProj, subI) => (
                <Card
                  bgColor={'gray.700'}
                  borderRadius={40}
                  shadow={'0 0 2px 2px'}
                  key={`proj${subI}`}
                >
                  <CardHeader>
                    <Heading
                      size="lg"
                      textShadow={'dark-lg'}
                      {...themeGradient}
                    >
                      {subProj.header}
                    </Heading>
                  </CardHeader>
                  <CardBody>
                    <Text pb={6} color={'white'} fontSize={20}>
                      {subProj.text}
                    </Text>
                    <SimpleGrid
                      columns={3}
                      spacing={2}
                      width="100%"
                      justifyContent="center"
                    >
                      {subProj.images.slice(0, 3).map((image, index) => (
                        <Image
                          key={index}
                          alt={`image ${index + 1}`}
                          src={image}
                          shadow={'0 0 4px 3px '}
                          h={'120px'}
                          w={'100%'}
                        ></Image>
                      ))}
                    </SimpleGrid>
                  </CardBody>
                  <CardFooter justifyContent="center">
                    <Button
                      color={'white'}
                      bgGradient={themeGradient.bgGradient}
                      onClick={() => handleSeeMoreClick(index, subI)}
                      mt={4}
                    >
                      See More
                    </Button>
                  </CardFooter>
                </Card>
              ))}
            </SimpleGrid>
          </VStack>
        ))}
      </VStack>

      {projIndex !== null && subIndex !== null && (
        <ProjectModal
          isOpen={isOpen}
          onClose={() => {
            setProjIndex(null);
            setSubIndex(null);
          }}
          project={projects[projIndex].subProjects[subIndex]}
        />
      )}
    </Flex>
  );
}
