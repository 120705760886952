import React from 'react';
import { Flex, Heading, Text, VStack } from '@chakra-ui/react';
import { aboutBody, aboutHead } from './aboutData';
import { themeHeader } from '../../themes/themes';

export default function About() {
  return (
    <Flex id="about-section" justifyContent="center">
      <Flex position="relative" color={'black'} bgColor={'white'}>
        <VStack maxW={1200} align="center">
          <Heading {...themeHeader}>{aboutHead}</Heading>
          <Text
            fontSize={{ base: '15px', md: '25px', lg: '36px' }}
            fontFamily={'Verdana (sans-serif)'}
            whiteSpace={'pre-line'}
            pl={4}
            pr={4}
          >
            {aboutBody}
          </Text>
        </VStack>
      </Flex>
    </Flex>
  );
}
