export const themeHeader = {
  p: '30px',
  fontSize: { base: '40px', md: '50px' },
};

export const themeGradient = {
  bgGradient: 'linear(to-l, #d19446 0%, #dcaf77 45%, #8c5e2a 100%)',
  bgClip: 'text',
  //   whiteSpace: 'nowrap',
};
