import React from 'react';
import { Image, Box } from '@chakra-ui/react';
import logo from '../../images/logo1.png';

export default function Footer() {
  return (
    <Box bgColor={'gray.700'} borderTopRadius={20} w={'100%'} p={4}>
      <Image src={logo} alt="logoFoot" maxW={'250px'} />
    </Box>
    // <Flex
    //   bg="black"
    //   h={100}
    //   color={'white'}
    //   justifyContent={'space-between'}
    //   align={'center'}
    // >
    //   <HStack w={'100%'}>
    //     <Image
    //       src={logo}
    //       alt="logo"
    //       p={5}
    //       h={100}
    //       display={['none', 'none', 'flex']}
    //     ></Image>
    //     <Spacer />
    //     <Icon as={FaEnvelope} className="icon" boxSize={[4, 6]} />
    //     <Text minW={'205px'} {...themeGradient}>
    //       sam@solidbuildingaspect.co.uk
    //     </Text>
    //     <Text>|</Text>
    //     <Icon as={FaMobile} className="icon" boxSize={[4, 6]} />
    //     <Text minW={'90px'} {...themeGradient}>
    //       07737551182
    //     </Text>
    //     <Spacer />
    //     <Image
    //       src={logo}
    //       alt="logo"
    //       h={100}
    //       p={5}
    //       display={['none', 'none', 'none', 'none', 'flex']}
    //     ></Image>
    //   </HStack>
    // </Flex>
  );
}
