import React from 'react';
import {
  Flex,
  SimpleGrid,
  Card,
  CardHeader,
  CardFooter,
  Image,
  CardBody,
  Heading,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';

import { servises, serFoot, serHead } from './servicesData.js';
import { themeHeader, themeGradient } from '../../themes/themes.js';

export default function Services() {
  const [wDevice] = useMediaQuery('(min-width: 400px)');
  return (
    <Flex
      color={'black'}
      bgSize={'100%'}
      bgColor={'white'}
      id="services-section"
      justifyContent={'center'}
      p={5}
    >
      <VStack>
        <Heading {...themeHeader}>{serHead}</Heading>
        <SimpleGrid spacing={8} minChildWidth={wDevice ? 400 : 280}>
          {servises.map((service, index) => (
            <Card
              bgColor={'gray.700'}
              borderRadius={40}
              shadow={'0 0 2px 2px'}
              key={`ser${index}`}
            >
              <CardHeader>
                <Heading size="lg" textShadow={'dark-lg'} {...themeGradient}>
                  {service.header}
                </Heading>
              </CardHeader>
              <CardBody>
                <Image
                  src={service.image}
                  alt={'image'}
                  w={'100%'}
                  maxH={300}
                  shadow={'dark-lg'}
                  borderRadius={10}
                ></Image>
              </CardBody>
              <CardFooter>
                <Text color={'white'} fontSize={20}>
                  {service.text}
                </Text>
              </CardFooter>
            </Card>
          ))}
        </SimpleGrid>
        <Text
          mt={20}
          p={3}
          maxW={1200}
          fontSize={{ base: '15px', md: '25px', lg: '26px' }}
          bgColor={'whiteAlpha.300'}
          borderRadius={40}
          align={'center'}
        >
          {serFoot}
        </Text>
      </VStack>
    </Flex>
  );
}
