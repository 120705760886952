function importAllImages(r, l) {
  r.keys().forEach(key => l.push(r(key)));
}

const letchworthImages = [];
const milbrookImages = [];
const teplowImages = [];
const bexleyImages = [];
const lewishamImages = [];
const swanleyImages = [];
const waleordeImages = [];

const letchworthContext = require.context(
  '../../images/projects/letchworth/',
  false,
  /\.(png|jpe?g|svg)$/
);
const milbrookContext = require.context(
  '../../images/projects/milbrook/',
  false,
  /\.(png|jpe?g|svg)$/
);
const teplowContext = require.context(
  '../../images/projects/teplow/',
  false,
  /\.(png|jpe?g|svg)$/
);
const bexleyContext = require.context(
  '../../images/projects/bexley/',
  false,
  /\.(png|jpe?g|svg)$/
);
const lewishamContext = require.context(
  '../../images/projects/lewisham/',
  false,
  /\.(png|jpe?g|svg)$/
);
const swanleyContext = require.context(
  '../../images/projects/swanley/',
  false,
  /\.(png|jpe?g|svg)$/
);
const waleordeContext = require.context(
  '../../images/projects/waleorde/',
  false,
  /\.(png|jpe?g|svg)$/
);

//SFS (STEEL FRAMING SYSTEM)
importAllImages(letchworthContext, letchworthImages);
importAllImages(milbrookContext, milbrookImages);
importAllImages(teplowContext, teplowImages);
//Aluminium decking
importAllImages(bexleyContext, bexleyImages);
importAllImages(swanleyContext, swanleyImages);
importAllImages(waleordeContext, waleordeImages);
importAllImages(lewishamContext, lewishamImages);

export const projHead = 'Projects';
export const projects = [
  {
    header: 'SFS (STEEL FRAMING SYSTEM)',
    subProjects: [
      {
        images: teplowImages,
        header: 'Development of 7 Residential Houses - Teplow:',
        text: 'Our latest intriguing project: 7 terraced houses built exclusively with fire-rated steel frame systems.',
        modalContent:
          'Our latest intriguing project: 7 terraced houses built exclusively with fire-rated steel frame systems. A rare choice over timber, showcasing cost-effectiveness, speedy construction, and stylish integration with the surrounding architecture.',
      },
      {
        images: letchworthImages,
        header: 'Hotel Premier Inn - Letchworth:',
        text: 'Our team took on the sole responsibility of constructing a hotel using SFS, completing the project within a timeframe of 8 months.',
        modalContent:
          'Our team took on the sole responsibility of constructing a hotel using SFS, completing the project within a timeframe of 8 months.',
      },
      {
        images: milbrookImages,
        header: 'Millbrook Park development - Mill Hill NW7',
        text: "Excited to contribute to Millbrook Park's vision of a thriving, sustainable community, our team played a key role in the large-scale regeneration project in Mill Hill East.",
        modalContent:
          "Excited to contribute to Millbrook Park's vision of a thriving, sustainable community, our team played a key role in the large-scale regeneration project in Mill Hill East. With a focus on imaginative new homes designed for modern living, we successfully constructed several blocks of flats using efficient SFS system. Completed in just 4 months, our contribution helped shape this vibrant community in NW7.",
      },
    ],
  },
  {
    header: 'ALUMINIUM DECKING',
    subProjects: [
      {
        images: bexleyImages,
        header: 'George Albert Court - Bexley:',
        text: 'Our team was commissioned to replace all non-compatible decking with fire-rated aluminum decking in a 3-story residential building.',
        modalContent:
          'Our team was commissioned to replace all non-compatible decking with fire-rated aluminum decking in a 3-story residential building. The project primarily focused on balconies and a terrace spanning approximately 500m2. The project was carried out at George Albert Court, Bexley.',
      },
      {
        images: swanleyImages,
        header: 'HIgh street Swanley:',
        text: 'In a new build project on High Street, Swanley, our team successfully installed Dura aluminum decking on all residential balconies, covering an area of over 140 m2',
        modalContent:
          'In a new build project on High Street, Swanley, our team successfully installed Dura aluminum decking on all residential balconies, covering an area of over 140 m2. The job was completed efficiently within a timeframe of just three weeks.',
      },
      {
        images: waleordeImages,
        header: 'Waleorde Court - London:',
        text: 'Our team was entrusted with the Waleorde Court project in London, where we replaced non-fire rated decking with aluminum decking in residential flats spanning three floors.',
        modalContent:
          'Our team was entrusted with the Waleorde Court project in London, where we replaced non-fire rated decking with aluminum decking in residential flats spanning three floors. The project covered an area of 160m2 and was successfully completed within a timeframe of six weeks.',
      },
      {
        images: lewishamImages,
        header: 'Granvel Park - Lewisham',
        text: 'Our team successfully replaced all non-fire rated decking with aluminum decking at a five-floor block of flats in Granvel Park, Lewisham, London.',
        modalContent:
          'Our team successfully replaced all non-fire rated decking with aluminum decking at a five-floor block of flats in Granvel Park, Lewisham, London. The job encompassed an area of 490m2 and was completed within a timeframe of three months.',
      },
    ],
  },
];
